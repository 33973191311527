/* eslint-disable */
import { ElementType, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { toggleChat, addUserMessage, renderCustomComponent } from '../../store/actions';
import { isWidgetOpened } from '../../store/dispatcher';
import { AnyFunction } from '../../utils/types';
import { GlobalState, InteractiveButton } from '../../store/types';

import apiClient from '../../apiClient';
import WidgetLayout from './layout';

type Props = {
  title: string;
  titleAvatar?: string;
  subtitle: string;
  senderPlaceHolder: string;
  profileAvatar?: string;
  showCloseButton?: boolean;
  fullScreenMode?: boolean;
  autofocus?: boolean;
  customLauncher?: AnyFunction;
  handleNewUserMessage: AnyFunction;
  handleInteractiveButtonClicked?: AnyFunction;
  handleConversationScroll?: AnyFunction;
  handleTextInputChange?: (event: any) => void;
  chatId?: string;
  handleToggle?: AnyFunction;
  launcherOpenLabel?: string;
  launcherCloseLabel?: string;
  launcherOpenImg?: string;
  launcherCloseImg?: string;
  sendButtonAlt?: string;
  showTimeStamp?: boolean;
  widgetConfig?: any;
  imagePreview?: boolean;
  zoomStep?: number;
  handleSubmit?: AnyFunction;
  emojis?: boolean;
};

function Widget({
  title,
  titleAvatar,
  subtitle,
  senderPlaceHolder,
  profileAvatar,
  showCloseButton,
  fullScreenMode,
  autofocus,
  customLauncher,
  handleNewUserMessage,
  handleInteractiveButtonClicked,
  handleConversationScroll,
  handleTextInputChange,
  chatId,
  handleToggle,
  launcherOpenLabel,
  launcherCloseLabel,
  launcherCloseImg,
  launcherOpenImg,
  sendButtonAlt,
  showTimeStamp,
  widgetConfig,
  imagePreview,
  zoomStep,
  handleSubmit,
  emojis,
}: Props) {
  const dispatch = useDispatch();
  const [resetKey, setResetKey] = useState(0);

  const showChat = useSelector((state: GlobalState) => state.behavior.showChat);
  const parentUrl = useSelector((state: GlobalState) => state.responses.parentUrl);
  const messageList = useSelector((state: GlobalState) => state.messages.messages);

  const [pendingMessage, setPendingMessage] = useState<string | null>(null);

  const toggleConversation = () => {
    if (!showChat) {
      if (window.parent && parentUrl) {
        window.parent.postMessage('isShowingChat', parentUrl);
      }
      // console.log("isShowingChat")
    } else {
      if (window.parent && parentUrl) {
        window.parent.postMessage('isHidingChat', parentUrl);
      }
      // console.log("isHidingChat")
    }

    dispatch(toggleChat());
    handleToggle ? handleToggle(isWidgetOpened()) : null;
  };

  const handleMessageSubmit = (userInput, userComponent: ElementType, plainFiles) => {
    // userComponentRef.current.addEventListener('click', ()=>console.log("button clicked"));
    if (!userInput.trim()) {
      return;
    }

    handleSubmit?.(userInput);

    handleNewUserMessage(userInput, plainFiles, messageList.length);

    if (!plainFiles) dispatch(addUserMessage(userInput));
    else dispatch(addUserMessage(userInput, userComponent));
  };

  const handleCustomeComponentSubmit = (userInput) => {
    dispatch(renderCustomComponent(userInput));
  };

  const onInteractiveButtonClicked = (event, button: InteractiveButton, messageID) => {
    event.preventDefault();
    handleInteractiveButtonClicked?.(button, messageID);
  };

  useEffect(() => {
    const handlePostMessage = (event: MessageEvent) => {
      if (!showChat) {
        if (window.parent && parentUrl) {
          window.parent.postMessage('isShowingChat', parentUrl);
        }
      }

      if (!isWidgetOpened()) {
        dispatch(toggleChat());
        handleToggle ? handleToggle(isWidgetOpened()) : null;
      }

      const { type, data } = event.data;
      if (type === 'EXTERNAL_MESSAGE') {
        if (!apiClient.sessionId) {
          setPendingMessage(data);
        } else {
          handleNewUserMessage(data);
          dispatch(addUserMessage(data));
        }
      }
    };

    const messageListener = (event: MessageEvent) => {
      if (event.data.type === 'EXTERNAL_MESSAGE') {
        handlePostMessage(event);
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [dispatch, showChat, parentUrl]);

  useEffect(() => {
    const sendPendingMessage = async () => {
      if (pendingMessage) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        handleNewUserMessage(pendingMessage);
        dispatch(addUserMessage(pendingMessage));
        setPendingMessage(null);
      }
    };

    if (apiClient.sessionId) {
      sendPendingMessage();
    }
  }, [apiClient.sessionId, pendingMessage, dispatch]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (isMobile && document.visibilityState === 'visible') {
        setResetKey((prev) => prev + 1);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <WidgetLayout
      key={resetKey}
      onToggleConversation={toggleConversation}
      onSendMessage={handleMessageSubmit}
      onSendCustomComponent={handleCustomeComponentSubmit}
      onInteractiveButtonClicked={onInteractiveButtonClicked}
      onHandleConversationScroll={handleConversationScroll}
      title={title}
      titleAvatar={titleAvatar}
      subtitle={subtitle}
      senderPlaceHolder={senderPlaceHolder}
      profileAvatar={profileAvatar}
      showCloseButton={showCloseButton!}
      fullScreenMode={fullScreenMode!}
      autofocus={autofocus!}
      customLauncher={customLauncher}
      onTextInputChange={handleTextInputChange}
      chatId={chatId!}
      launcherOpenLabel={launcherOpenLabel!}
      launcherCloseLabel={launcherCloseLabel!}
      launcherCloseImg={launcherCloseImg!}
      launcherOpenImg={launcherOpenImg!}
      sendButtonAlt={sendButtonAlt!}
      showTimeStamp={showTimeStamp!}
      widgetConfig={widgetConfig}
      imagePreview={imagePreview}
      zoomStep={zoomStep}
      emojis={emojis}
    />
  );
}

export default Widget;
